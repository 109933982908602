import React from 'react'
import Layout from '../../components/layout'
import Validate from '../../components/validate'
import Nav from '../../components/ui/nav'
import Footer from '../../components/landing/footer'
import QABox from '../../components/ui/qa'
import ContactBox from '../../components/ui/contact'

// Get the URLs depending on our environment
let redirectUri = 'http://localhost:8888/oauth-callback'
let lambdaUri = 'http://localhost:8888'

const currentEnv = process.env.GATSBY_ENV
if (currentEnv === "prod"){
  redirectUri = 'https://www.paverops.com/oauth-callback'
  lambdaUri = 'https://www.paverops.com'
}
if (currentEnv === "stage"){
  redirectUri = 'https://stage--paverops.netlify.app/oauth-callback'
  lambdaUri = `https://stage--paverops.netlify.app`
}


// markup
const HelpCenter = () => {
  return (
    <Layout>
      <main>
        <Validate lambdaUri={lambdaUri} redirectUri={redirectUri}>
          <div className="non-landing white">
            <Nav page={"support"} items={[
              {url: "/support/", text: "Support Hub"},
              {url: "/app/", style: "border", text: "App Home"}
            ]} />
            <div className="topper">
              <div className="img-wrapper">
                <img src="/images/toppersupport.png" alt="An angular slice of a road being repaired with heavy machinery" />
              </div>
              <div className="deck-wrapper">
                <div className="deck">
                  <h1>
                    Help Center
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <section className="app-body support help">
            <div className="help-container">
              <QABox 
                q="How do I create a new feature?"
                a={`
                  <p><strong>This functionality is only available to an organization’s creator.</strong></p> 
                  <ol>
                    <li>Click or tap on the Create Feature button near the top right corner to activate the Create Feature tool. Your cursor will be replaced with a plus sign.</li>
                    <li>Click or tap on the map to start your line feature. Each subsequent click on the map will create a new vertice.</li>
                    <li>Finish the drawing by double clicking or tapping where you would like its final vertice. This will complete the drawing phase.</li>
                    <li>An attribute update modal will appear. Choose which layer this feature should be added to.</li>
                    <li>Fill out the feature’s attributes</li>
                    <li>Click or tap Add New Feature to load your new feature and its attributes to the database and map.</li>
                  </ol>
                `}
              />
              <QABox 
                q="How do I update an existing feature’s attributes?"
                a={`
                  <p><strong>This functionality is only available to an organization’s creators. A creator can only edit their organization’s features.</strong></p> 
                  <ol>
                    <li>Select the feature(s) you want to edit.</li>
                    <li>In the selected features panel, add or update the desired attribute values. If more than one feature is selected, all will be updated with the added or modified attribute value.</li>
                    <li>Click or tap the Update feature button to complete the update process.</li>
                  </ol>
                `}
              />
              <QABox 
                q="How do I delete a feature from PaverOps?"
                a={`
                  <p><strong>This functionality is only available to an organization’s creators. A creator can only edit their organization’s features.</strong></p> 
                  <ol>
                    <li>Select the feature(s) you want to delete.</li>
                    <li>Click or tap the Delete feature button at the bottom of the selected feature panel to delete the feature(s) from the database and map. This action cannot be undone.</li>
                  </ol>
                `}
              />
              <QABox 
                q="When I select a feature, I cannot edit its attributes. Why is this?"
                a={`
                  <ul>
                    <li>This could be due to your assigned role, which you can see below your username in the top left corner. If it shows “Viewer” you do not have rights to edit features.</li>
                    <li>If it shows “Creator” then it may be because the selected feature(s) does not belong to your organization. Creators can only modify features previously created or uploaded by their organization.</li>
                  </ul>
                `}
              />
              <QABox 
                q="How do I delete all the features I created?"
                a={`
                  <p><strong>This functionality is only available to an organization’s creators.</strong></p> 
                  <ol>
                    <li>Click or tap on the icon to the right of the layer name under the “My Data” group to select all your features in that layer.</li>
                    <li>Once the Selected Features Panel opens, scroll down and click or tap the Delete Features button. Click or tap again to confirm the delete request. Once committed, the delete request cannot be undone and the deleted features cannot be recovered.</li>
                  </ol>
                `}
              />
              <QABox 
                q="How do I add users to PaverOps for my organization?"
                a={`
                  <p>Please contact your organization’s PaverOps admin and they can take the required steps or to contact PaverOps for support.</p> 
                `}
              />
              <QABox 
                q="How do I become an editor for my organization?"
                a={`
                  <p>Please contact your organization’s PaverOps administrator and they can take the required steps.</p> 
                `}
              />
              <QABox 
                q="My organization’s service area polygon needs to be updated. How do we do that?"
                a={`
                  <p>Ask your organization’s PaverOps admin to send an email to support@paverops.com with the updated service area attached (must be a shapefile, feature class, or GeoJSON), and with a subject line of “[Insert your Org name] updated service area”.</p> 
                `}
              />
              <QABox 
                q="Which internet browser is recommended for PaverOps?"
                a={`
                  <p>PaverOps can be used on any HTML5 compliant browser of your choice, including Google Chrome, Microsoft Edge, Mozilla Firefox, and Apple Safari. Microsoft Internet Explorer is not recommended.</p> 
                `}
              />
              <QABox 
                q="In the selected features table view, I cannot select or zoom to a specific feature. Why is that?"
                a={`
                  <p>As an ESRI partner, we leverage ESRI technology throughout the PaverOps tool and, unfortunately, we are currently limited in providing that functionality. ESRI is aware of this and we hope that functionality is made available in the near future. Thank you for understanding and your patience.</p> 
                `}
              />
              <QABox 
                q="How often should our organization update our data?"
                a={`
                  <p>We recommend, at a minimum, an organization update its PaverOps data once a year. However, there are times when updating all or parts of your data more frequently is important.</p> 
                `}
              />
              <QABox 
                q="Should utility service laterals be added to PaverOps?"
                a={`
                  <p>PaverOps provides a domain/drop-down list value in the System Level field for lateral, although, in most cases, utility service laterals may not be necessary for how most users engage with PaverOps. Typically, where there is a main, especially in a street, there are likely laterals to the nearby homes, businesses, or structures. In areas outside of dense urban or suburban areas, such as ranchettes or rural areas, where laterals tend to be longer and less predictable, this data can be extremely valuable to your or other organizations and reduce the potential for accidental incidents.</p> 
                  <p>However, the decision to include lateral is at  the discretion of the utility service provider.</p>
                `}
              />
              <ContactBox text="Don't see your question answered?" />
            </div>
          </section>
          <Footer />
        </Validate>
      </main>
    </Layout>
  )
}

export default HelpCenter

